import PropTypes from 'prop-types';
import React, { Component } from 'react';

require('./signup.scss');
class SignUp extends Component {
  
  render() {
    // console.log(p5);
   
    return (
      <div className="sign-up__container">
        <div 
          class="ctct-inline-form" 
          data-form-id="809848cf-dde1-4b03-b36e-8450d8882591"
        >
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {

};


export default SignUp;
